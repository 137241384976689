.Login {
  min-height: 100vh;
}

.Login .ant-row-flex-middle {
  height: 100vh;
}

.Login .login-form-component {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  
  width: 70rem;
  height: 50vh;
  
  margin-top: 20vh;
}

.Login .login-divider {
  height: 100%;
  background-color: var(--color-dark-yellow-beonly);
}

.Login .login-form {
  padding: 25px;
}

.Login .login-form-input {
  height: 47px;
  width: 24rem;
  border-radius: 10px;
}

.Login .login-form-input ::placeholder {
  font-size: 14px;
}

.Login .login-form-input .ant-input {
  font-size: 14px;
  padding-left: 7px;
}

.Login .login-form-input .ant-input:-internal-autofill-selected {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255,1);
}

.Login .login-form .ant-input-affix-wrapper:hover,
.Login .login-form .ant-input-affix-wrapper-focused {
  border-color: var(--color-light-yellow-beonly);
}

.Login .login-beon-logo {
  content: '';
  display: block;
  background: transparent no-repeat center center;
  height: 40%;
  width: 40%;
  background-size: 100% auto;
  background-image: url("../../../../images/logo.png")
}

.Login .login-form .ant-btn-primary {
  display: block;
  width: 100%;
  height: 47px;
  
  border: none;
  border-radius: 10px;
  
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-gray-beonly);
  
  background-image: linear-gradient(to right, var(--color-light-yellow-beonly) 0%, var(--color-dark-yellow-beonly) 51%, var(--color-gray-beonly) 100%);
  background-size: 200% auto;
}


.Login .tenant-select-search-container {
  margin: 2rem 0;
}

.Login .tenant-select-search-input {
  width: 60%;
  margin: 0 1rem;
  border-radius: 10px;
}

.main_title {
  text-align: center;
  margin: 2rem 0 1rem;
}

table {
  table-layout: fixed;
  width: 100%;
  margin-top: 3rem;
  border-collapse: collapse;
  border: 2px white solid;
}

td {
  padding: 12px;
}

tr:hover {
  background-color: #708090 !important;
}

tbody tr:nth-child(even) {
  background-color: #e5e4e2;
}

.action {
  text-align: right;
}
