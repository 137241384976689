.linkSearch {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}

ul.listSearch li:before {
    content: '-';
    margin-right: 5px;
}
ul.listSearch {
    padding: 0;
    margin-top: 10px;
    list-style: none;
}

.logoArea span.textLogo {
    font-weight: bold;
    font-size: 21px;
    margin-left: 11px;
    color: #5b2989;
}
.logoArea img {
    max-width: 65px;
}
.logoArea {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}

.colExclude {
    position: relative;
}

.closeButton {
    position: absolute;
    right: 20px;
    top: 12px;
    font-weight: bold;
    color: #ccc;
    cursor: pointer;
}

.customSlider {
    max-width: 320px;
}

.customRow {
    border-bottom: 1px solid #ccc;
    padding-bottom: 6px;
}

.flexConfigurations {
    display: flex;
    flex-wrap: wrap;
}

.flexConfigurations div {
    flex: 1;
    text-align: center;
}

.flexConfigurations__header div {
    text-align: center;
    padding: 10px 0;
    font-weight: bold;
}

.flexConfigurations > .ant-row.field-component-row {
    width: 100%;
}

.eachClassification {
    padding: 0 15px;
    min-width: 20%;
}

.eachProps {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 26px;
    margin-left: 11vw;
}

.eachProps .fieldProps {
    display: flex;
    margin-left: 0px;
    max-height: 40px;
    padding-right: 2px;
    margin-bottom: 12px;
}

.eachProps .fieldProps .fieldPropsName {
    width: 325px;
    margin: 0 1px;
}

.eachProps .fieldProps .fieldPropsValue {
    width: 225px;
    margin: 0 10px;
}

.eachBreakpoint {
    display: flex;
    justify-content: space-between;
}

.eachBreakpoint .ant-row {
    flex: 1;
    margin-right: 19px;
}

.search-form__grid {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
}

.search-form__grid > :nth-child(1) {
    text-align: left;
}

.search-form__grid > :nth-child(2) {
    grid-column-start: 2;
}

.search-form__grid__long-inputs {
    grid-template-columns: 1fr;
}

.search-form__grid__adjustments {
    display: grid !important;
    grid-template-columns: 1fr;
}

.search-form__grid__adjustments > :nth-child(1){
   text-align: left;
}

.search-form__table table {
    margin: 0;
}

.search__submenu-arrow_hidden .ant-menu-submenu-arrow {
    display: none !important;
}
