/* CONTAINER */
.support-menu__container {
  display: flex;
  flex-direction: column;

  width: 100%;
  background-color: #FFF;
  border-radius: 10px;
}

/* BOTOES */
.support-menu__container .support-menu__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  font-weight: 500;
  border-radius: 7px;
}

.support-menu__btn.support-btn__success-blog, .support-menu__btn.support-btn__success-blog:hover {
  color: #FFF;
  background-color: #FE6F5B;
  border: none;
  margin: 1.5rem 1.5rem 0;
}

.support-menu__btn.support-btn__movidesk, .support-menu__btn.support-btn__movidesk:hover {
  margin: 1.5rem;
  color: #000;
  background-color: #f0f2f5;
  border-color: #f0f2f5;
  font-weight: 400;
}
