@import '~antd/dist/antd.css';

:root {
  --color-dark-yellow-beonly: rgb(232, 183, 23);
  --color-light-yellow-beonly: rgb(255, 222, 18);
  --color-gray-beonly: rgb(56, 76, 94);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App header {
  color: #fff;
}

.app-header__logo {
  display: block;
  text-indent: -999em;
  background: transparent left center no-repeat;
  background-size: auto 25px;
  background-image: url('../../images/logo.png');
}

.app-header__menu-divider  {
  position: relative;
}

.app-header__menu-divider ::after {
  content: '';
  position: absolute;
  top: 20px;
  right: 0;
  bottom: 20px;
  width: 1px;
  background-color: #e8e8e8;
}

.app-header__menu-tutorial {
  color: #1E3042;
  background: #E8B717;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.app-header__preview-col {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  gap: 3px;
}

.ant-page-header,
.ant-layout-sider-light,
.ant-layout-sider-light .ant-menu-light,
.ant-layout-sider-light .ant-menu-submenu > .ant-menu {
  background: transparent;
}

.ant-row.ant-form-item {
  justify-content: center;
}

.params_group {
  margin-top: 2.5em;
}

.field-component-row {
  margin-bottom: 10px;
  /* padding-left: 20px; */
}
.field-component-row .help {
  cursor: pointer;
  margin-top: 0;
}
/* .field-component-row .ant-form-item {
    margin-bottom: 0;
} */

.ant-form-item-label {
  white-space: normal;
}

h3.ant-typography {
  font-size: 16px;
  margin-bottom: 1.5em;
}
h4.ant-typography {
  font-size: 15px;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.help {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 10px;
}

/* Spartez Chat session */

.support-chat-icon[data-v-01dd00ea] {
  background-color: #8968a8 !important;
}

.spartez-support-chat-container .top-header[data-v-c846ae0e] {
  background-color: #8968a8 !important;
}

.chats-list button.new-conversation[data-v-40a59ef0] {
  background-color: #8968a8 !important;
}

.chats-list .ops .login-options[data-v-40a59ef0] {
  display: none;
}

.searchCanvas {
  width: 100%;
  padding: 40px;
}

/* mic */
.mic {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: 26px;
  padding: 0;
  white-space: nowrap;
  color: black;
}

.mic:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  transform: translateX(120%);
  transition: transform 2s linear;
  display: block;
  width: 100%;
  height: 100%;
  background-color: limegreen;
  border-radius: 3px;
}

.mic.active {
  color: #fff;
  width: 120px;
}
.mic.active:after {
  transform: translateX(0);
}

.mic .listening {
  font-size: 12px;
  text-transform: uppercase;
  padding-right: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .mic {
    transition: all 0.2s linear;
  }

  .mic.active {
    animation: mic-active infinite 1s ease-in-out;
  }

  .mic:after {
    transition: transform 0.2s linear;
  }
}

@keyframes mic-active {
  1% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.preview-site-btn, .preview-site-btn:hover {
  color: var(--color-gray-beonly);
  background: var(--color-dark-yellow-beonly);
  border-color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
