.varible-group-label {
    padding-top: 20px;
}
.variables-groups > div:first-of-type .varible-group-label {
    padding-top: 20px;
}

.varible-group-label .ant-badge .ant-badge-count {
    transform: translate(150%, 20%);
}

.variable-subgroup-label {
    text-transform: uppercase;
    line-height: 2em;
    margin-bottom: 0 !important;
}
.variable-subgroup-list {
    margin-bottom: 20px;
}

.variable-row {
    padding: 10px 0 10px 0;
    min-height: 42px;
    border-bottom: 1px solid #efefef;
}

.variable-row.has-definitions {
    /*  */
}

.variable-bullets-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-right: 30px;
    min-height: 24px;
}

.variable-bullets-wrapper .variable-bullet {
    margin-top: 1px;
    margin-bottom: 1px;
}

/* form has absolute position to float over bullets */
.variable-bullets-wrapper .variable-bullet-add,
.variable-bullets-wrapper .segmentation-bullet-form {
    position: absolute;
    right: 0;
}
