.catalog-health-main {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 10px;
  width: 100%;
}

.catalog-sku-section {
  margin: 15px 0;
}

.catalog-products-section {
  margin: 15px 0;
}

.catalog-products-updated {
  background-color: white;
  border-radius: 5px;
  margin: 15px 0;
  padding: 10px;
}

.catalog-products-updated > div:first-of-type > div:first-of-type label {
  color: black;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-left: 18px;
}

.catalog-sku-cards-section {
  display: flex;
  min-height: 130px;
}

.catalog-products-card-section {
  display: flex;
  min-height: 130px;
}