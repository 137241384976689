.page-element-row {
    padding: 10px 5px 10px 10px;
    min-height: 60px;
    border: 1px solid #e8e8e8;
    background-color: #FFF;

    border-radius: 5px;
    margin: 5px;
}

.page-element-row .title-col {
    font-size: 16px;
}

.page-element-row .actions-col {
    text-align: right;
}

.page-element-row .actions-col .ant-btn {
    margin-right: 5px;
    min-width: 28px;
    width: 28px;
    height: 28px;
}

.page-element-row .actions-col .ant-btn .anticon {
    font-size: 12px;
}

.page-element-row .ant-col {
    transition: all linear 0.2s;
}

.page-element-row.hidden .ant-col {
    opacity: 0.5;
}

.page-element-row.preview {
    border-color: transparent;
    box-shadow: 0 0 0 2px #ffd591;
}
