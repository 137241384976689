.name-column-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.product-name-text {
  margin-bottom: 2px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.price-info-text {
  color: gray !important;
  font-size: 13px;
}

.error-info-text {
  color: #f5222d !important;
  font-size: 13px;
}
