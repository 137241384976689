.tool-tip {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.tool-tip-container {
  align-items: center;
  background-color: #656565;
  border-radius: 5px;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 54px;
}

.tool-tip-text {
  color: white;
  font-weight: 590;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
}

.arrow {
  border-top: 11px solid #666666;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  height: 0;
  width: 0;
}