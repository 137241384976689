/* CONTAINER */
.support_float-btn-container {
  z-index: 1000;
  position: fixed;
  bottom: 20px;
  right: 15px;
  height: fit-content;
}

/* BOTAO */
.support_float-btn-container .support_float-btn {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: none;
}

.support_float-btn-container .support_float-btn:hover {
  border-color: var(--color-gray-beonly);
}

/* IMAGEM*/
.support_float-btn-container .ant-image {
  position: absolute;
  top: 0;
  left: 0;
}

.support_float-btn-img {
  border-radius: 100%;
}

/* MODAL*/
.support_float-btn-modal {
  position: absolute !important;
  bottom: 0;
  right: 20px;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.support_float-btn-modal .ant-modal-content {
  border-radius: 16px;
  width: 375px;
}