.sider-layout-style aside {
  background-color: #F0F2F5;
}

.content-layout-style header {
  background-color: #F0F2F5;
  color: black !important;
};

.content-layout-style main {
  background-color: #F0F2F5 !important;
}

.content {
  align-items: center !important;
  border-bottom: 1px solid #dee0e2 !important;
  display: flex !important;
  flex-direction: column !important;
  height: fit-content !important;
  justify-content: center !important;
  background-color: white;
}

.collapse-panel-style div:nth-child(2) div {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.collapse-panel-style div:nth-child(2) div button {
  background: none;
  border: none;
  box-shadow: none;
  margin-left: 1rem;
}
