/* VISIBILITY */
.actions_visibility-icon.visible, .actions_visibility-icon.visible:hover, .actions_visibility-icon.visible:focus {
  background-color: #83c567;
  color: #fff;
  border-color: #83c567;
}

.actions_visibility-icon.hidden, .actions_visibility-icon.hidden:hover, .actions_visibility-icon.hidden:focus {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
}

/* PREVIEW */
.actions_preview-icon.preview--on, .actions_preview-icon.preview--on:hover, .actions_preview-icon.preview--on:focus {
  background-color: #ff9b3d;
  border-color: #ff9b3d;
  color: #FFF;
}

.actions_preview-icon.preview--off:focus, .actions_preview-icon.preview--off:hover {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
}

/* MOVE */
.actions_move-icon.up, .actions_move-icon.down {
  min-width: 24px;
  width: 24px;
  height: 24px;
}

.actions_move-icon.up span, .actions_move-icon.down span {
  font-size: 10px;
}
