/* reset drawer padding, add padding to tabs pane */
.page-edit-wrapper .ant-drawer-body {
    padding: 0;
}

.page-edit-wrapper .ant-drawer-body .ant-tabs-extra-content .ant-btn {
    margin-right: 10px;
}

.page-edit-wrapper .ant-drawer-body .ant-tabs-content {
    padding: 0 20px 20px 20px;
}

.has-errors {
    color: red;
}
