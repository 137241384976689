.radial-graph-card-main {
  align-items: center;
  background-color: white;
  border-radius: 5px;
  display: flex;
  height: 235px;
  justify-content: space-around;
  margin: 0 7.5px;
  min-width: 235px;
  min-height: 235px;
  padding: 12px;
}

.radial-card-title {
  color: black;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  width: 70%;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.radial-card-info {
  color: #818181;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.circular-graph-container {
  width: 65%;
}

.radial-card-simple-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100%;
}

.radial-card-complex-section {
  display: flex;
  flex-direction: column;
}

.complex-values-section {
  display: flex;
  flex-direction: column;
  margin: 2.5px 0;
}

.complex-values-container {
  align-items: center;
  display: flex;
}

.positive-icon {
  background-color: #1890FF;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  margin-right: 10px;
}

.negative-icon {
  background-color: #D9D9D9;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  margin-right: 10px;
}

.complex-text-info {
  color: #818181;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 5px;
}

.positive-text-value {
  color: #1890FF;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  margin-bottom: 2px;
}

.negative-text-value {
  color: #8C8C8C;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  margin-bottom: 2px;
}