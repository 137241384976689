.page-element-row .order-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-color: red;
}

.page-element-row .tags-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 3px;
}

.page-element-row .tags-col .tags-col__tag {
  min-width: 80px;
  text-align: center;
}
