.page-edit-panel {
    margin: 20px 0;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
}
.page-edit-panel .ant-empty {
    margin: 20px 0;
}
.page-edit-panel .ant-page-header {
    border-bottom: 1px solid #e8e8e8;
}
.page-edit-panel .ant-page-header-title-view-title {
    font-weight: normal;
}
